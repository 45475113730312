<template>
  <div id="parkOverview">
    <a-page-header
      :title="linkTitle"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <div class="iframeBox">
      <iframe :src="linkPath"></iframe>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { userInfo } from "@/utils/userInfo";
export default {
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState("global", {
      linkPathList: (state) => {
        return state.linkPathList;
      }, // 获取父应用的user信息
    }),
    // 根据参数显示正确的内置链接
    linkPath() {
      let linkPath;
      this.linkPathList.forEach((item) => {
        if (this.$route.query.linkType === item.linkType) {
          linkPath = item.linkPath;
        }
      });
      return linkPath;
    },
    linkTitle() {
      let linkTitle;
      this.linkPathList.forEach((item) => {
        if (this.$route.query.linkType === item.linkType) {
          linkTitle = item.linkTitle;
        }
      });
      return linkTitle;
    }
  },
  watch: {
  },
  created() {
    //园区概览埋点
    this.$sensors.track("PlatformMenu", {
      ...userInfo(),
      tab_name: this.linkTitle,
    });
  },
};
</script>
<style scoped>
.iframeBox {
  width: 100%;
  height: calc(100vh - 235px);
}
.iframeBox iframe {
  width: 100%;
  height: 100%;
  display: inline-block;
  border: 0px;
}
</style>
